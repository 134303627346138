<template>
	<div class="home">
		<div class="three-slider">
      <div class="slider__wrapper" v-if="Object.keys(sliderItems).length">
        <div class="slide" v-for="(item, index) in sliderItems" :key="index" :class=" !item.immagine ? 'slide--center' : ''" :order="index">
          <div class="slide__wrapper">
            <article class="slide__words">
              <div class="words__row" v-for="(row, ind) in item.titolo.split('/')" :key="ind">
                <div class="word__wrapper" v-for="(word, inx) in row.split('#')" :key="inx">
                  <p class="word" :class=" (word.includes('{') && word.includes('}')) ? 'font2' : '' ">{{word.replace('{', '').replace('}', '')}}</p>
                </div>
              </div>
            </article>
            <figure class="slide__image">
              <div class="image__wrapper">
                <div class="image__container">
									<div class="img" v-if="item.tipo == 'immagine'">
										<img :src="item.immagine">
									</div> 
                </div>
              </div>
            </figure>
					</div>
				</div>
			</div>
			<div class="slider__dots" v-if="Object.keys(sliderItems).length">
				<div class="dots__container">
					<div class="dot" :class="n == 1 ? 'dot--center' : ''" v-for="(n, ind) in Object.keys(sliderItems).length" :key="ind" :data-dot-number="n">
						<div class="dot__number">
							<p>0{{n}}</p>
						</div>
						<div class="dot__wrapper">
							<div class="dot__container">
								<span class="dot__span"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="home--mobile">
			<section class="slide" v-for="(item, index) in sliderItems" :key="index" :class=" !item.immagine ? 'canvas' : ''">
				<div class="logoCanvas" v-if="!item.immagine"></div>
				<div class="image" v-else>
					<img :src="item.immagine">
				</div>
				<div class="title">
					<span v-for="(wrd, ind2) in item.titolo.split('/').join(',').split('#').join(',').split(',')" :key="ind2" :class=" (wrd.includes('{') && wrd.includes('}')) ? 'font2' : '' ">
						{{wrd.replace('{', '').replace('}', '')}}
					</span>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import * as LOLLO from '@/plugins/LolloSlider.js'
import axios from 'axios'
export default {
	data() {
		return {
			sliderItems: {},
			wizSize: {width: window.innerWidth, height: window.innerHeight}
		}
	},
	methods: {
		getHome() {
			let home_id = parseInt(this.$t('homepage.id'));
			axios
			.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + home_id)
			.then(response => {
				this.sliderItems = response.data.sliders
			});
		},
		calcWinsize(){
			this.wizSize = { width:window.innerWidth, height: window.innerHeight}
		},
		isIpadPro() {
			let response;
			let ratio = window.devicePixelRatio || 1;
			let screen = {
				width : window.screen.width * ratio,
				height : window.screen.height * ratio
			};
			if(
			(screen.width === 2048 && screen.height === 2732) || //iPad Pro Portrait
			(screen.width === 2732 && screen.height === 2048) || //iPad Pro Landscape
			(screen.width === 1536 && screen.height === 2048) || //iPad
			(screen.width === 2048 && screen.height === 1536)){	
				response = true;
			}else {
				response = false;
			}
			return response;
		},
		isMobile() {
			if(this.wizSize.width <= 768 || this.isIpadPro()){
				return true;
			}else{
				return false;
			}
		}
	},
	watch: {
		'$route.params.locale': function(){
			this.getHome()
			if(!this.isMobile() && Object.keys(this.sliderItems).length){
				let container = document.querySelector('.slide--center .image__container')
				container.innerHTML = ''
			}else{
				let container = document.querySelector('.home--mobile .slide.canvas .logoCanvas')
				container.innerHTML = ''
			}
		}
  },
	beforeMount(){
		this.getHome()
		window.addEventListener('resize', () => {
			this.calcWinsize();
		});
	},
  updated() {
		if(!this.isMobile() && Object.keys(this.sliderItems).length){
			let container = document.querySelector('.slide--center .image__container')
			new LOLLO.Logo3D(container)
			new LOLLO.ThreeSlideShow(document.querySelector('.three-slider'))
		}else{			
			let container = document.querySelector('.home--mobile .slide.canvas .logoCanvas')
			new LOLLO.Logo3D(container, true)
		}
  }
}
</script>

<style lang="scss">
.home {
  position: relative;
  overflow: hidden;
  height: 100vh;
	@include mobile {
		height: auto
	}
	.home--mobile {
		padding-top: 60px;
		display: none;
		@include mobile {
			display: block;
		}
		.slide {
			position: relative;
			width: 100%;
			height: 70vh;
			display: flex;
			align-items: center;
			justify-content: center;
			.logoCanvas {
				position: relative;
				width: 100%;
				height: auto;
			}
			.image {
				position: relative;
				width: 70%;
				height: auto;
				img {
					width: 100%;
					height: auto;
				}
			}
			.title {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				//flex-direction: column;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				span {
					position: relative;
					margin: 0;
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 15vw;
					line-height: 15vw;
					text-transform: uppercase;
					margin-right: 5vw;
					&.font2 {
						font-family: 'Saol Display';
						font-size: 15vw;
						line-height: 10vw;
						font-weight: 400;
						color: $black;
						padding-bottom: 2vh;
					}
				}
			}
		}
	}
  .three-slider{
    position: relative;
    width: 100%;
    height: 100vh;
		@include mobile {
			display: none;
		}
    img{
      width: 100%;
    }
    .slider__wrapper{
      position: relative;
      width: 100%;
      height: 100%;
      .slide{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 11;
        opacity:0;
        visibility:hidden;
        &.slide--center{
          z-index: 31;
          .slide__wrapper{
            .slide__words{
              .words__row{
                .word__wrapper{
                  .word{
                    transform: translateY(0%);
                  }
                }
              }
            }
          }
        }
				&[order="1"]{
					.slide__wrapper {
						.slide__image {
							width: 30vw;
						}
					}
				}
				&[order="2"]{
					.slide__wrapper {
						.slide__image {
							width: 26vw;
						}
					}
				}
        .slide__wrapper{
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .slide__words{
            .words__row{
              display: flex;
              justify-content: center;
              align-items: center;
              .word__wrapper{
                position: relative;
                overflow:hidden;
                padding:0 3rem;
                .word{
                  position: relative;
                  transform: translateY(100%);
                  margin: 0;
                  font-family: 'Suisse Int';
                  font-weight: 500;
                  color: $black;
                  font-size: 5vw;
                  line-height: 5vw;
                  text-transform: uppercase;
                  &.font2 {
                    font-family: 'Saol Display';
                    font-size: 5vw;
                    line-height: 4vw;
                    font-weight: 400;
                    color: $black;
                    padding-bottom: 3vh;
                  }
                }
              }
            }
          }
          .slide__image{
            position: absolute;
            width: 38vw;
            height: calc(38vw * 1280 / 1920);
            overflow: hidden;
            .image__wrapper{
              position: relative;
              width: 100%;
              height: 100%;
              .image__container{
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .img{
                  position: relative;
                  width: 40%;
                  // height: 100%;
                  line-height: 0;
                }
              }
            }
          }
        }
      }
    }
    .slider__dots{
      position: absolute;
      right: 1vw;
      bottom:5rem;
      z-index: 101;
      .dot{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 1rem;
        cursor:pointer;
        &.dot--center{
          cursor:default;
          .dot__wrapper{
            .dot__container{
              .dot__span{
                width: 100%;
              }
            }
          }
        }
        .dot__number{
          position: relative;
          p{
            color:$black;
            font-family: 'Suisse Int';
            font-weight: 500;
            margin: 0;
          }
        }
        .dot__wrapper{
          position: relative;
          width: 80px;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot__container{
            position: absolute;
            right: 0;
            width: 100%;
            height: 1px;
            .dot__span{
              position: absolute;
              right: 0;
              top: 0;
              width: 20%;
              height: 100%;
              background-color: $black;
            }
          }
        }
      }
    }
  }
}
</style>