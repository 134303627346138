import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
gsap.registerPlugin(ScrollToPlugin);

export class ThreeSlide{
	constructor(slide){
		this.DOM = {slide:slide}
		this.DOM.slideWrapper = this.DOM.slide.querySelector('.slide__wrapper')
		this.DOM.slideImage = this.DOM.slide.querySelector('.slide__image')
		this.DOM.imageWrapper = this.DOM.slide.querySelector('.image__wrapper')
		this.DOM.imageContainer = this.DOM.slide.querySelector('.image__container')

		this.DOM.wordsWrapper = [...this.DOM.slide.querySelectorAll('.word__wrapper')]
	}
	setCenter(){
		this.isCenter = true
		this.DOM.slide.classList.add('slide--center')
		gsap.to(this.DOM.slide,{
				duration:0,
				autoAlpha:1,
				'z-index': 31
		})
	}
	reset(){
		gsap.to(this.DOM.slide,{
			duration:0,
			autoAlpha:0,
			y:0,
			'z-index':11
		})
		gsap.to(this.DOM.imageWrapper,{
			duration:0,
			transform:'none'
		})
		gsap.to(this.DOM.imageContainer,{
			duration:0,
			transform:'none'
		})
		this.DOM.slide.classList.remove('slide--center')
	}

	move(direction, gap){
		let duration = 1.2
		let ty = direction === 'up' ? '+=' + gap*-1 : '+=' + gap
		let wordTy = direction === 'up' ? '-100%' : '100%'
		if(this.DOM.slide.classList.contains('slide--center')){
			return new Promise( resolve => {
				gsap.to(this.DOM.imageWrapper,{
					duration:duration,
					y:ty,
					ease:'power2.inOut',
					onComplete: () => resolve()
				})
				this.DOM.wordsWrapper.forEach( (wordWrapper,i) => {
					let word = wordWrapper.querySelector('.word')
					let delay = i*0.1
					gsap.to(word,{
						duration:0.2,
						delay:delay,
						ease:'power1.in',
						y: wordTy
					})
				})
			})
		}
		else{
			return new Promise( resolve => {
				gsap.to(this.DOM.imageContainer,{
					duration:duration,
					y:ty,
					ease:'power2.inOut',
										delay:0.9,
					onComplete: () => {
						resolve()
					}
				})
				this.DOM.wordsWrapper.forEach( (wordWrapper,i) => {
						let word = wordWrapper.querySelector('.word')
						let delay = i*0.1
						gsap.to(word,{
								duration:0.2,
								delay:0.9 + delay,
								ease:'power2.out',
								y:'0%'
						})
				})
			})
		}
	}
}

export class ThreeDot{
	constructor(dot){
			this.DOM = {dot:dot}
			this.DOM.dotWrapper = this.DOM.dot.querySelector('.dot__wrapper')
			this.DOM.dotWrapperWidth = this.DOM.dotWrapper.getBoundingClientRect().width
			this.DOM.dotSpan = this.DOM.dot.querySelector('.dot__span')
			if(this.DOM.dot.classList.contains('dot--center')){
					this.DOM.dotSpanInitialWidth = this.DOM.dotSpan.getBoundingClientRect().width / 5
			}
			else{
					this.DOM.dotSpanInitialWidth = this.DOM.dotSpan.getBoundingClientRect().width
			}
	}
	setCenter(){
			this.DOM.dot.classList.add('dot--center')
	}
	reset(){
			this.DOM.dot.classList.remove('dot--center')
	}
	move(){
			if(this.DOM.dot.classList.contains('dot--center')){
					return new Promise( resolve => {
							gsap.to(this.DOM.dotSpan,{
									duration: 0.5,
									width: this.DOM.dotSpanInitialWidth,
									onComplete: () => resolve()
							})
					})
			}
			else{
					return new Promise( resolve => {
							gsap.to(this.DOM.dotSpan,{
									duration:0.5,
									width:this.DOM.dotWrapperWidth,
									onComplete: () => resolve()
							})
					})
			}
	}
}

export class ThreeSlideShow{
	constructor(slider){
		this.DOM = {slider: slider}
		this.DOM.slideWrapper = this.DOM.slider.querySelector('.slider__wrapper')
		this.arraySlides = [...this.DOM.slider.querySelectorAll('.slide')].map( slide => new ThreeSlide(slide))
		this.arrayDots = [...this.DOM.slider.querySelectorAll('.dot')].map( dot => new ThreeDot(dot))

		this.arraySlidesTotal = this.arraySlides.length;
		this.center = 0;
		this.isAnimating = false;

		//Calcolo gap
		this.imageRect = this.arraySlides[0].DOM.slideImage.getBoundingClientRect();
		this.gap = this.imageRect.height + 10;

		this.initEvents()
		this.setVisibleSlides()
	}

	initEvents(){
	window.addEventListener('resize', () => {
		this.imageRect = this.arraySlides[0].DOM.slideImage.getBoundingClientRect();
		this.gap = this.imageRect.height;
	})

	this.arrayDots.forEach( dotClass => {
			dotClass.DOM.dot.addEventListener('click', () => {
					let dot = dotClass.DOM.dot
					let dotCliccato = dot.dataset.dotNumber
					let dotCorrente = this.arrayDots[this.center].DOM.dot.dataset.dotNumber
	let diff = Number(dotCliccato) - Number(dotCorrente);
	let direction;
					if(diff === 0) return;
					if(diff > 0){
							direction = 'up'
					}
					else{
							direction = 'down'
					}
					this.navigate(direction, dotCliccato)
			})
	})

	window.addEventListener('mousewheel', e => {
			let wDelta = e.wheelDelta < 0 ? 'up' : 'down';
			// if(this.isAnimating) return
			// this.isAnimating = true
			// console.log(wDelta);
			// if(wDelta === 'up'){
					this.navigate(wDelta)
			// }
	})
	}

	setVisibleSlides(){
		this.centerSlide = this.arraySlides[this.center];
		this.centerSlide.setCenter();
		this.centerDot = this.arrayDots[this.center];
		this.centerDot.setCenter();
	}

	navigate(direction, dotCliccato){
			if(this.isAnimating) return
			this.isAnimating = true
			//isGlobalAnimating = this.isAnimating
			let upcomingPos
			if(dotCliccato){
					upcomingPos = dotCliccato - 1
					this.center = dotCliccato -1
			}
			else{
					upcomingPos = direction === 'up' ?
							this.center < this.arraySlidesTotal - 1 ? this.center + 1 : Math.abs(this.arraySlidesTotal-1-this.center) :
							this.center >= 1 ? this.center - 1 : Math.abs(this.arraySlidesTotal-1+this.center)
					this.center = direction === 'up' ?
							this.center < this.arraySlidesTotal-1 ? this.center+1 : 0 :
							this.center > 0 ? this.center-1 : this.arraySlidesTotal-1

					//console.log(upcomingPos)

			}

			this.upcomingSlide = this.arraySlides[upcomingPos]
			this.upcomingDot = this.arrayDots[upcomingPos]

			gsap.to(this.upcomingSlide.DOM.slide, {duration:0,autoAlpha:1, 'z-index':51})
			gsap.to(this.upcomingSlide.DOM.imageWrapper, {duration:0,y: direction === 'up' ? this.gap : -1*this.gap})
			this.upcomingSlide.DOM.wordsWrapper.forEach( wordWrapper => {
					let word = wordWrapper.querySelector('.word')
					gsap.to(word, {duration:0,y: direction === 'up' ? '100%' : '-100%'})
			})

			const movingSlides = [this.upcomingSlide, this.centerSlide]

			let promises = movingSlides.map( slide => {
				return slide.move(direction, this.gap)
			})

			const movingDots = [this.upcomingDot, this.centerDot];
				movingDots.forEach( dot => {
				promises.push(dot.move())
			})
			Promise.all(promises).then( () => {
				movingSlides.forEach( slide => slide.reset());
				movingDots.forEach( dot => dot.reset());
				this.setVisibleSlides();
				this.isAnimating = false;
					//isGlobalAnimating = this.isAnimating
			})

	}
}

export class Logo3D{
	constructor(container, isMobile = false){
			this.ww = window.innerWidth
			this.hh = window.innerHeight
			// this.container = document.querySelector('.slide')
			this.container = container
			this.canvasWidth = this.container.getBoundingClientRect().width
			// this.canvasHeight = this.container.getBoundingClientRect().height
			this.canvasHeight = this.canvasWidth * 1280 / 1920
			this.aspectRatio = this.canvasWidth / this.canvasHeight
			this.mobileAnimated = false

			this.mousePos = {
					x: this.ww/2
			}


			this.debugObject = {
					test:0
			}
			/* this.gui = new dat.GUI()
			this.gui.add(this.debugObject,'test').min(0).max(10).step(0.001).name('test') */


			this.dpr = Math.min(window.devicePixelRatio,2)

			this.scene = new THREE.Scene()

			this.axesHelper = new THREE.AxesHelper(5)
			// this.scene.add(this.axesHelper)

			this.clock = new THREE.Clock()
			this.elapsedTime = 0

			// this.camera = new THREE.PerspectiveCamera(75,this.canvasWidth/this.canvasHeight,0.01,100)
			this.camera = new THREE.OrthographicCamera(- 1*this.aspectRatio, 1*this.aspectRatio, 1, - 1, 0.01, 100)
			this.camera.position.set(0, 0, 20)
			this.scene.add(this.camera)

			this.renderer = new THREE.WebGLRenderer({
					alpha:true,
					antialias: true,
			})
			this.renderer.setClearColor(0xff0000,0)
			this.renderer.setSize(this.canvasWidth,this.canvasHeight)
			this.renderer.setPixelRatio(this.dpr)
			this.renderer.setClearColor( 0xffffff, 0 )
			this.renderer.physicallyCorrectLights = true
			this.renderer.outputEncoding = THREE.sRGBEncoding
			this.renderer.toneMapping = THREE.ReinhardToneMapping

			// this.controls = new OrbitControls(this.camera, this.renderer.domElement)
			// this.controls.enableDamping = true

			this.geometry = new THREE.PlaneGeometry(1,1,1)
			this.material = new THREE.MeshBasicMaterial({color:0xff0000})
			this.mesh = new THREE.Mesh(this.geometry, this.material)
			// this.scene.add(this.mesh)

			this.directionalLight = new THREE.DirectionalLight(0xffffff, 3)
		/* 	this.directionalLight.castShadow = true
			this.directionalLight.shadow.mapSize.set(1024, 1024) */
			/* this.directionalLight.shadow.camera.far = 15
			this.directionalLight.shadow.camera.left = - 7
			this.directionalLight.shadow.camera.top = 7
			this.directionalLight.shadow.camera.right = 7
			this.directionalLight.shadow.camera.bottom = - 7 */
			this.directionalLight.position.set(0, 2, 7)
			this.scene.add(this.directionalLight)

			// const dracoLoader = new DRACOLoader()
			// dracoLoader.setDecoderPath('/draco/')
			this.gltfLoader = new GLTFLoader()
			// gltfLoader.setDRACOLoader(dracoLoader)
			this.mixer = null
			this.gltfExternal = null;
			this.gltfLoader.load(
				'../models/wearlight.glb',
				(gltf) => {
					this.gltfExternal = gltf
					//!Per orthographik camera
					gltf.scene.scale.set(0.03, 0.03, 0.03)
					//!Per perspective camera
					// gltf.scene.scale.set(0.5, 0.5, 0.5)
					gltf.scene.position.y = 0.25
					gltf.scene.position.x = 0
					this.scene.add(gltf.scene)

					// gui.add(gltf.scene.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.001).name('rotationY')
					// gltf.scene.rotation.x = Math.PI * 0.5
					// gui.add(gltf.scene.children[0].position, 'y').min(-100).max(100).step(0.001).name('positionY')
					// camera.lookAt(gltf.scene.children[0].position)
					// gltf.scene.children[0].position.set(0,10,0)

					gltf.scene.children[0].material.color = new THREE.Color(0xff0000)
					this.updateAllMaterials()
					this.animate()
				}
			)

			this.container.appendChild(this.renderer.domElement)

			// console.log(this.canvasWidth)

			this.resize()
			if(!isMobile){
				this.mouseMove()
			}else{
				window.requestAnimationFrame(() => {
					this.mobileMove()
				});
			}
			this.render()

	}

	mobileMove(){
		if(!this.mobileAnimated){
			if(this.gltfExternal){
				this.mobileAnimated = true
				gsap.to(this.gltfExternal.scene.rotation,{
					duration:0,
					y:Math.PI*0.2,
				})
			}
			window.requestAnimationFrame(() => {
				this.mobileMove()
			});
		}
	}

	updateAllMaterials(){
		this.scene.traverse((child) => {
			if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
				child.material.needsUpdate = true
			}
		})
	}

	resize(){
			window.addEventListener('resize', () => {
					// Update sizes
					this.canvasWidth = this.container.getBoundingClientRect().width
					this.canvasHeight = this.canvasWidth * 1280 / 1920

					// Update camera
					this.camera.aspect = this.canvasWidth / this.canvasHeight
					this.camera.updateProjectionMatrix()

					// Update renderer
					this.renderer.setSize(this.canvasWidth, this.canvasHeight)
					this.renderer.setPixelRatio(this.dpr)

			})
	}

	render(){
			this.elapsedTime = this.clock.getElapsedTime()
			this.elapsedTime++
			// console.log(this.elapsedTime)
			this.renderer.render(this.scene, this.camera)
			// this.renderer.render(this.camera,this.scene)

			// this.controls.update()

			this.camera.updateProjectionMatrix()

			window.requestAnimationFrame( () =>
					this.render()
			)
	}


	mouseMove(){
			window.addEventListener('mousemove', (e) => {
					this.mousePos.x = 2*((e.clientX - this.ww) / this.ww) + 1
					this.mousePos.y = 2*((e.clientY - this.hh) / this.hh) + 1

					//console.log(this.mousePos.y)
					if(this.gltfExternal){
							gsap.to(this.gltfExternal.scene.rotation,{
									duration:0.3,
									y:Math.PI*0.4*this.mousePos.x,
									x:Math.PI*0.1*this.mousePos.y,
									ease:'power1.inOut'
							})
					}
			})
	}

	animate(){
			//let prog = 75;
			//let counter = 0;
			if(this.gltfExternal){
					// console.log(this.gltfExternal.scene)
					/* gsap.to(this.gltfExternal.scene.rotation,{
							duration:3,
							y: 2*Math.PI,
							// ease:'power2.inOut',
							onUpdate: () => {
									counter++
									// this.camera.position.set
									// this.camera.fov =  prog + 10*(Math.abs(Math.sin((counter/90)*Math.PI)))
									// console.log(Math.abs(Math.sin((counter/90)*Math.PI)))
									// console.log('updating')
							}
					}) */
					// gsap.to(this.container,{
					//     duration:1,
					//     y: '-100%',
					//     ease:'power2.inOut',
					//     delay:3.2
					// })
			}
	}
}

/* if(document.querySelector('.pagina-wearlight') !== null){
	let container = document.querySelector('.slide--center .image__container')
	console.log(container)
	new Logo3D(container)
}


document.addEventListener("DOMContentLoaded", () =>{
	let body = document.body;
	// new customFunction.GeneralFunctions();

	if(body.classList.contains('page-template-confirm_mail') && document.querySelector('#activateUser') !== null){
			// console.log('activate')
			activateUser();
	}
	if(body.classList.contains('page-template-login') || body.classList.contains('page-template-signup') || body.classList.contains('page-template-recovery-password')){
			new LoginFunctions();
	}


}); */